import './index.css';
import React, {useEffect} from "react";
import ScrollTrigger from "gsap/ScrollTrigger"
import gsap from "gsap/gsap-core";

function Page5() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
            trigger: ".page5-m",
            onToggle: ({progress, direction, isActive}) => {
                if (isActive || direction < 0) {
                    document.getElementsByClassName("topbar-m")[0].classList.add("white")
                } else {
                    document.getElementsByClassName("topbar-m")[0].classList.remove("white")
                }
            },
            start: "top top"
        });

        ScrollTrigger.create({
            trigger: ".page5-m",
            toggleActions: "play none play reset",
            pin: ".page5-m",
            pinSpacing: false,
            start: "top top",
            end: "bottom top"
        });
    }, []);


    return (
        <div id="page5-m" className="page5-m">
            <div className="wrapper">
                <div className="about">
                    About <br/>
                    .
                </div>
                <div className="cnt">
                    <b>엠.제로</b>는 <b>제품 디자이너</b>와 <b>소재 연구원</b>으로<br/>
                    이루어진 전문가 그룹입니다.
                </div>
            </div>

        </div>
    );
}

export default Page5;
