import './index.css';
import React, {useEffect} from "react";
import ScrollTrigger from "gsap/ScrollTrigger"
import gsap from "gsap/gsap-core";

function Page3() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
            trigger: ".page3-m",
            onToggle: ({progress, direction, isActive}) => {
                if (!isActive && direction > 0) {
                    document.getElementsByClassName("topbar-m")[0].classList.add("white")
                } else {
                    document.getElementsByClassName("topbar-m")[0].classList.remove("white")
                }
            },
            start: "top top"
        });

        ScrollTrigger.create({
            trigger: ".page3-m",
            toggleActions: "play none play reset",
            pin: ".page3-m",
            pinSpacing: false,
            start: "bottom bottom",
        });


    }, []);

    return (
        <div className="page3-m">
            <div className="wrapper">
                <div className="content">
                    <div className="letter-m"/>
                    <div className="cnt">
                        소재의 가능성을 증폭시키는 디자인
                    </div>

                    <div className="desc">
                        Designing for Material Possibility
                    </div>
                </div>

                <div className="content">
                    <div className="letter-0"/>
                    <div className="cnt">
                        제로 베이스로 시작되는 연구
                    </div>
                    <div className="desc">
                        Research from Zero-base
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page3;
