import './index.css';
import React from "react";

function Page6() {

    return (
        <div id="page6-m-p" className="page6-m page6-m-p">
            <div className="wrapper">
                <div className="content">
                    <div className="about">
                        What we do <br/>
                        .
                    </div>
                    <div style={{display: "flex", flexDirction: "row"}}>
                        <div className="letter-o"/>
                        <div className="letter-v"/>
                    </div>
                    <div className="text">
                        Zero Virus
                    </div>
                    <div className="cnt">
                        생활 환경 개선
                    </div>
                    <div className="desc">
                        Improvement in Living Environment
                    </div>
                </div>
                <div className="content">
                    <div style={{display: "flex", flexDirction: "row"}}>
                        <div className="letter-o"/>
                        <div className="letter-p"/>
                    </div>
                    <div className="text">
                        Zero Plastic
                    </div>
                    <div className="cnt">
                        소재의 지속가능성
                    </div>
                    <div className="desc">
                        Materials for Sustainability
                    </div>
                </div>


            </div>

        </div>
    );
}

export default Page6;
