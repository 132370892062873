import './index.css';
import React, {useEffect} from "react";
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

function Page3() {
    const DESKTOP_OFFSET = 240;
    const DESKTOP_OFFSET_LONG = 600;


    useEffect(() => {
        ScrollTrigger.create({
            trigger: ".page3",
            onToggle: ({progress, direction, isActive}) => {
                if (isActive || direction > 0) {
                    document.getElementsByClassName("topbar")[0].classList.add("dark")
                } else {
                    document.getElementsByClassName("topbar")[0].classList.remove("dark")
                }
            },
            start: "top top"
        });

        gsap.registerPlugin(ScrollTrigger);

        let offset = 0;
        gsap.to(".page3 .content", {
            position: "fixed",
            scrollTrigger: {
                trigger: ".page3",
                toggleActions: "play none play reset",

                start: "top top",
                end: "+=" + DESKTOP_OFFSET_LONG,
            }
        });

        offset += DESKTOP_OFFSET_LONG;

        // 0 사라짐
        gsap.to(".page3 .content .letter-o.o1",
            {
                opacity: 0,
                duration: .2,
                scrollTrigger: {
                    trigger: ".page3",
                    toggleActions: "play complete none reset",

                    start: "top+=" + offset + " top",
                    end: "+=" + DESKTOP_OFFSET,
                }
            });

        offset += DESKTOP_OFFSET;

        // M desc
        gsap.set(".page3 .content .m-desc", {
            y: +50
        });
        gsap.to(".page3 .content .m-desc", {
            opacity: 1,
            y: 0,
            duration: .2,
            scrollTrigger: {
                trigger: ".page3",
                toggleActions: "play complete play reset",

                start: "top+=" + offset + " top",
                end: "+=" + DESKTOP_OFFSET_LONG,
            }
        });

        offset += DESKTOP_OFFSET_LONG;

        gsap.to(".page3 .content .m-desc", {
            opacity: 0,
            y: -50,
            duration: .2,
            scrollTrigger: {
                trigger: ".page3",
                toggleActions: "play complete none reset",

                start: "top+=" + offset + " top",
                end: "+=" + DESKTOP_OFFSET,
            }
        });

        offset += DESKTOP_OFFSET;

        // 사라지고 O등장
        gsap.from(".page3 .content .letter-o.o2",
            {
                opacity: 0,
                duration: .2,
                scrollTrigger: {
                    trigger: ".page3",
                    toggleActions: "play complete none reset",

                    start: "top+=" + offset + " top",
                    end: "+=" + DESKTOP_OFFSET_LONG,
                }
            });

        offset += DESKTOP_OFFSET_LONG;

        // M사라지고
        gsap.to(".page3 .content .letter-m.m1", {
            opacity: 0,
            duration: .2,
            scrollTrigger: {
                trigger: ".page3",
                toggleActions: "play complete none reset",

                start: "top+=" + offset + " top",
                end: "+=" + DESKTOP_OFFSET,
            }
        });

        offset += DESKTOP_OFFSET;

        // 0 desc
        gsap.set(".page3 .content .o-desc", {
            y: +50
        });
        gsap.to(".page3 .content .o-desc", {
            opacity: 1,
            y: 0,
            duration: .2,
            scrollTrigger: {
                trigger: ".page3",
                toggleActions: "play complete play reset",

                start: "top+=" + offset + " top",
                end: "+=" + DESKTOP_OFFSET_LONG,
            }
        });

        offset += DESKTOP_OFFSET_LONG;

        gsap.to(".page3 .content .o-desc", {
            opacity: 0,
            y: -50,
            duration: .2,
            scrollTrigger: {
                trigger: ".page3",
                toggleActions: "play complete none complete",

                start: "top+=" + offset + " top",
                end: "+=" + DESKTOP_OFFSET,
            }
        });

        offset += DESKTOP_OFFSET;

        // M등장
        gsap.from(".page3 .content .letter-m.m2",
            {
                opacity: 0,
                duration: .2,
                scrollTrigger: {
                    trigger: ".page3",
                    toggleActions: "play complete none reset",

                    start: "top+=" + offset + " top",
                    end: "+=" + DESKTOP_OFFSET_LONG,
                }
            });

        offset += DESKTOP_OFFSET_LONG;

        gsap.fromTo(".page3 .content", {
            top: "50%"
        }, {
            top: "unset",
            bottom: "50vh",
            position: "absolute",
            scrollTrigger: {
                trigger: ".page4",
                toggleActions: "play none play reset",
                start: "top bottom",
                end: "top top",
            }
        });

    }, []);


    return (
        <div id="page3" className="page3">
            <div className="content">
                <div className="align-box m">
                    <div className="letter-m m1"/>
                    <div className="letter-m m2"/>
                    <div className="o-desc">
                        <div className="cnt">
                            제로 베이스로 시작되는 연구
                        </div>
                        <div className="eng">
                            Research from Zero-base
                        </div>
                    </div>
                </div>
                <div className="align-box">
                    <div className="letter-o o1"/>
                    <div className="letter-o o2"/>
                    <div className="m-desc">
                        <div className="cnt">
                            소재의 가능성을 증폭시키는 디자인
                        </div>
                        <div className="eng">
                            Designing for Material Possibility
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page3;
