import React from 'react';
import StyledPopup from 'reactjs-popup';
import './index.css';

const Popup = ({isMobile}) => {
    const [isOpen, setIsOpen] = React.useState(true);
    return (
        <StyledPopup
            open={isOpen}
            position={isMobile ? 'top center' : 'top left'}
            closeOnDocumentClick
        >
            <div className="popup-close" onClick={() => setIsOpen(false)}/>
            <div className="popup-content-title">
                [신주발행공고]<br/>
            </div>
            <div className="popup-content-desc">
                <br/>
                당사는 2023년 10월 25일 임시주주총회에서 정관 제9조 제2항에 의거하여 크라우드펀딩을 통한 일반공 모 방식의 신주 발행을 결의하였으므로, 상법 제418조 제4항에 따라 아래의
                사항을
                공고합니다.<br/><br/>
            </div>
            <div className="popup-content-desc center">
                -아래-<br/><br/>
            </div>
            <div className="popup-content-desc">
                1. 신주식의 종류와 수: 상환전환우선주식 198주 ~ 2,463주 (청약 현황에 따라 변동)<br/>
                2. 신주식의 발행 가격: 1주당 금 202,970원 (자본금으로 계상하는 금액 1주 당 금 10,000원) <br/>
                3. 신주의 발행 총액: 금 40,188,060원 ~ 499,915,110원 (청약
                현황에 따라 변동)<br/>
                4. 신주의 인수방법: 크라우드펀딩을 통한 일반공모 (회사 정관 제9조 제2항에 근거)<br/>
                5. 신주의 청약기간: 2023년 10월 26일부터 2023년 11월 15일<br/>
                6. 신주의 주금납입일자: 2023년 11월 27일<br/>
                7. 청약취급처: 주식회사 크라우디 (www.ycrowdy.com)<br/>
                8. 주금납입처: 국민은행 강남타운지점<br/>
                9. 청약증거금: 청약금액의 100%<br/>
                10. 기타<br/><br/>
            </div>
            <div className="popup-content-desc center">
                1) 이 유상증자와 관련하여 이사회에서 정하지 아니한 사항은 대표이사에게 일임함 <br/>
                2) 위 일정은 관계기관과의 협의과정에서 변경될 수 있음<br/><br/>
                2023년 10월 19일<br/><br/>
                주식회사 랩엠제로 대표이사 신태호 (직인생략)<br/>
            </div>
        </StyledPopup>
    );
}

export default Popup
