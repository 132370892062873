import React from 'react'
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';


const center = { lat: 37.547310000281, lng: 127.063321763572 };

function MyComponent(props) {
    const {styles} = props;
    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        map.position = center;
        new window.google.maps.Marker({
            position: center,
            map: map
        });
        setMap(map)
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
        >
            <GoogleMap
                mapContainerStyle={styles}
                center={center}
                zoom={16}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <Marker/>
            </GoogleMap>
        </LoadScript>
    )
}

export default React.memo(MyComponent)
