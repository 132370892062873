import './index.css';
import React, {useEffect} from "react";
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Project from "./Project";
import maskcase from "./maskcase@3x.png" ;
import photocatalysisInterior from "./photocatalyst-interior@3x.png";
import plasticCurrency from "./plastic-currency@3x.png";
import photocatalysis from "./photocatalyst@3x.png";
import mycelium from "./mycelium@3x.png";
import superhydrophobicity from "./Superhydrophobicity.png";

function Page7() {

    useEffect(() => {
        ScrollTrigger.create({
            trigger: ".page7",
            onToggle: ({progress, direction, isActive}) => {
                if (isActive || direction > 0) {
                    document.getElementsByClassName("topbar")[0].classList.add("dark")
                } else {
                    document.getElementsByClassName("topbar")[0].classList.remove("dark")
                }
            },
            start: "top top"
        });
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.create({
            trigger: ".page7",
            toggleActions: "play none play reset",
            pin: ".page7 .wrapper",
            pinSpacing: false,
            start: "bottom bottom"
        });

    }, []);


    return (
        <div className="page7" id="page7">
            <div className="wrapper">
                <div className="section">
                    <div className="section-title">진행 중인 프로젝트</div>
                    <div className="section-subtitle">Current Projects</div>
                    <div style={{height: 119}}/>
                    <div className="project-wrapper">
                        <Project
                            title={"마스크 살균 케이스"}
                            subtitle={"UV Face Mask Sterilizer"}
                            content={"Zero V 마스크 케이스는 UVC-LED를 활용한 마스크 살균 기능의 휴대용 케이스입니다. \n" +
                                "외관 소재는 바이오 플라스틱을 적용하여 인체에 안전한 플라스틱 소재를 적용하되, 플라스틱 사용량을 절감할 수 있도록 하였습니다."}
                            imagePath={maskcase}
                        />
                        <Project
                            title={"광촉매 실내 마감재"}
                            subtitle={"Photocatalysis Interior Material"}
                            content={"M.0의 부설연구소에서 연구 개발 중인 광촉매 소재는, 2019년 첫번째 프로토타입이 가구에 접목되어 소개되면서 해외 매거진 Wallpaper에도 실리게 되었습니다. \n" +
                                "M.0가 진행중인 실내 환경 개선 프로젝트의 하나로서 기존의 광촉매 소재보다 더욱 효과적으로 실내 공기질을 개선시킬 수 있는 실내마감재를 개발 중에 있으며, 첫번째 제품은 2021년 초에 선보일 예정입니다."}
                            imagePath={photocatalysisInterior}
                        />
                    </div>
                </div>

                <div className="section">
                    <div className="section-title">소재 연구</div>
                    <div className="section-subtitle">R&D</div>
                    <div style={{height: 119}}/>
                    <div className="project-wrapper">
                        <Project
                            title={"플라스틱 화폐"}
                            subtitle={"Plastic Currency"}
                            content={"현대자동차그룹 오픈 이노베이션 플랫폼 제로원의 크리에이터인 신태호 대표가 진행한 플라스틱 시스템 연구 프로젝트입니다. \n" +
                                "\n" +
                                "본 프로젝트는 플라스틱 재활용 시스템의 미미한 작동을 가시화하여 수면 아래 가라앉은 하부 시스템을 부상시키고자 합니다. 자본의 논리를 건져 올려 이를 재배치시키는 문제를 고민하며, ‘끊어진 시스템’을 다시 잇고, 사회에 직접적인 영향을 미칠 수 있는 선순환 구조를 제시합니다."}
                            imagePath={plasticCurrency}
                        />
                        <Project
                            title={"광촉매"}
                            subtitle={"Photocatalysis"}
                            content={"광촉매 소재는 공기 중 유해물질을 분해시켜 실내 환경을 정화할 수 있는 소재입니다. 광촉매 기술이 발전하면서 반도체, 에너지  산업  등 다양한 분야에 적용되고 있으나, 양산 기술과 경제성의 이유로 실생활에서 만날 수 있는 제품의 범위는 제한적입니다.  \n" +
                                "\n" +
                                "광촉매 물질이 포함된 혼합 물질로 개발된 신소재는, 가시광선에 반응하여 분자구조를 변화시키는 방법으로 유해물질을 분해합니다. 이는 실내 요소 및 가구에 적용되어 에너지원이 필요치 않은 공기정화장치로서 기능할 수 있습니다.\n"}
                            imagePath={photocatalysis}
                        />
                        <Project
                            title={"버섯 균사체"}
                            subtitle={"Mycelium"}
                            content={"버섯 균사체 기반의 바이오 소재는 제품 생산에서 부터 환경을 고려하고 폐기되는 자원을 재생하는 방법으로 다양한 응용 제품이 출시되고 있습니다. \n" +
                                "M.0는 버섯균사체를 배양하여 다양한 유기물과 조합하는 연구와 인테리어 제품에 소재를 적용하는 디자인 작업을 진행하고 있습니다."}
                            imagePath={mycelium}
                        />
                        <Project
                            title={"초소수성"}
                            subtitle={"Superhydrophobicity"}
                            content={"초소수성 물질은 물방울과 표면의 접촉각이 150도 이상으로 물에 젖지 않는 성질을 지니고 있습니다. 초소수성 재료의 표면은 물에 대한 반발력으로 인해 물방울이 재료로 스며들지 않고 표면 위를 롤링하게 됩니다. \n" +
                                "\n" +
                                "습기가 없기 때문에 깨끗한 표면을 유지하는 자정 효과를 갖고 있어, 청결과  위생이 요구되어지는 생활 환경에 다양한 적용이 가능합니다."}
                            imagePath={superhydrophobicity}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page7;
