import './Project.css';
import React from "react";
function Project(props) {
    const {
        imagePath,
        title,
        subtitle,
        content
    } = props;
    return (
        <div className="project">

            <div className="image"
                 style={{
                     backgroundImage: "url("+imagePath+")"
                 }}/>
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
            <div className="content" >
                {
                    content.split("\n").map((line, index) => {
                        return <div key={index}>{line}</div>
                    })
                }
            </div>

        </div>
    );
}

export default Project;
