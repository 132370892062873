import './index.css';
import React, {useEffect, useState} from "react";

function Topbar() {
    const [index, setIndex] = useState(0);
    const [hash, setHash] = useState(window.location.hash);

    useEffect(() => {
        const pages = [
            document.querySelector("#page1"),
            document.querySelector("#page2"),
            document.querySelector("#page3"),
            document.querySelector("#page4"),
            document.querySelector("#about-us"),
            document.querySelector("#what-we-do"),
            document.querySelector("#page7"),
            document.querySelector("#contact")
        ].map(page => page?.offsetTop);

        window.addEventListener("scroll", (e) => {
            for (let i = 0; i < 8; i++) {
                if (window.scrollY >= pages[i] && (i === 7 || window.scrollY < pages[i + 1])) {
                    setIndex(i);
                    break
                }
            }
        })

    }, []);


    return (
        <div className="topbar">
            <div style={{width: 37}} className={"menu " + (hash === "#about-us" ? "focused" : "")} onClick={() => {
                window.location.href = "#about-us";
                setHash("#about-us")
            }}>About
            </div>
            <div style={{width: 74}} className={"menu " + (hash === "#what-we-do" ? "focused" : "")} onClick={() => {
                window.location.href = "#what-we-do";
                setHash("#what-we-do")
            }}>What we do
            </div>
            <div style={{width: 46}} className={"menu " + (hash === "#contact" ? "focused" : "")} onClick={() => {
                window.location.href = "#contact";
                setHash("#contact")
            }}>Contact
            </div>

            <div className="side">
                <div className={"button " + (index === 0 ? "active" : "")}
                     onClick={() => {
                         window.scrollTo(0, document.querySelector("#page1").offsetTop + 1)
                     }}
                />
                <div className={"button " + (index === 1 ? "active" : "")}
                     onClick={() => {
                         window.scrollTo(0, document.querySelector("#page2").offsetTop + 1)
                     }}/>
                <div className={"button " + (index === 2 ? "active" : "")}
                     onClick={() => {
                         window.scrollTo(0, document.querySelector("#page3").offsetTop + 1)
                     }}/>
                <div className={"button " + (index === 3 ? "active" : "")}
                     onClick={() => {
                         window.scrollTo(0, document.querySelector("#page4").offsetTop + 800)
                     }}/>
                <div className={"button " + (index === 4 ? "active" : "")}
                     onClick={() => {
                         window.scrollTo(0, document.querySelector("#about-us").offsetTop + 1)
                     }}/>
                <div className={"button " + (index === 5 ? "active" : "")}
                     onClick={() => {
                         window.scrollTo(0, document.querySelector("#what-we-do").offsetTop + 1)
                     }}/>
                <div className={"button " + (index === 6 ? "active" : "")}
                     onClick={() => {
                         window.scrollTo(0, document.querySelector("#page7").offsetTop + 1)
                     }}/>
                <div className={"button " + (index === 7 ? "active" : "")}
                     onClick={() => {
                         window.scrollTo(0, document.querySelector("#contact").offsetTop + 1)
                     }}/>
            </div>
        </div>
    )
}

export default Topbar;
