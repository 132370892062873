let removeToast;

const copyToClipboard = (val) => {
    const element = document.createElement('textarea');
    element.value = val;
    element.setAttribute('readonly', '');
    element.style.position = 'absolute';
    element.style.left = '-9999px';
    document.body.appendChild(element);
    element.select();
    let returnValue = document.execCommand('copy');
    document.body.removeChild(element);
    if (!returnValue) {
        throw new Error('copied nothing');
    }

    const toast = document.getElementById("toast");


    if( toast.classList.contains("reveal")) {
        clearTimeout(removeToast);
        removeToast = setTimeout(function () {
            document.getElementById("toast").classList.remove("reveal")
        }, 1000)
    } else {
        removeToast = setTimeout(function () {
            document.getElementById("toast").classList.remove("reveal")
        }, 1000);
    }
    toast.classList.add("reveal")

};


export {copyToClipboard};