import './App.css';
import React from "react";
import Descktop from "./Desktop"
import Mobile from "./Mobile"
import Popup from './common/Popup';

function App() {
    const isMobile = window.innerWidth < 1190;
    return (
        <div className="App" id="app">
            {
                isMobile && <Mobile/>
            }
            {
                !isMobile && <Descktop/>
            }
            {/*TODO: REMOVE BELOW TO REMOVE POPUP*/}
            <Popup isMobile={isMobile}/>
        </div>
    );
}

export default App;
