import './index.css';
import React, {useEffect} from "react";
import gsap from "gsap/gsap-core";
import ScrollTrigger from "gsap/ScrollTrigger";

function Page5() {

    useEffect(()=> {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.create({
            trigger: ".page5",
            toggleActions: "play none none reset",
            pin: ".page5 .wrapper",
            pinSpacing: false,
            start: "top top",
            end: "bottom top"
        });


    });

    return (
        <div className="page5" id="about-us">
            <div className="wrapper">
                <div className="content">
                    <div className="part1">
                        <div className="about">
                            About<br/>
                            .
                        </div>

                        <div className="desc">
                            <b>엠.제로</b>는 <b>제품 디자이너</b>와 <b>소재 연구원</b>으로 이루어진 전문가 그룹입니다.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page5;
