import './index.css';
import React, {useEffect} from "react";
import gsap from 'gsap/gsap-core';
import ScrollTrigger from 'gsap/ScrollTrigger';

function Page1() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
            trigger: ".page1-m",
            toggleActions: "play none play reset",
            pin: ".page1-m",
            pinSpacing: false,
            start: "top top",
            end: "bottom top"
        });

        ScrollTrigger.create({
            trigger: ".page2-m",
            onToggle: ({progress, direction, isActive}) => {
                if (isActive || direction > 0) {
                    document.getElementsByClassName("topbar-m")[0].classList.remove("white")
                } else {
                    document.getElementsByClassName("topbar-m")[0].classList.add("white")
                }
            },
            start: "top top"
        });
    }, []);

    useEffect(() => {
            document.getElementById("video-m").setAttribute("muted", true);

            document.getElementById("video-m").setAttribute("defaultMuted", true)
        }
        , []);

    return (
        <div id="page1-m" className="page1-m">
            <video className="background"
                   autoPlay muted loop playsInline id="video-m" width="100%" height="100%">
                <source src="bg.mp4" type="video/mp4"/>
            </video>
            <div className="wrapper">
                <div className={"content"}>
                    <div className={"letter-m"}/>
                    <div className={"letter-o"}/>
                </div>
            </div>
        </div>
    );
}

export default Page1;
