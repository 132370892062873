import './index.css';
import React, {useEffect, useState} from "react";
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

function Page4() {
    const DESKTOP_OFFSET_LONG = 600;

    const [bold,setBold] = useState(0);

    useEffect(() => {

        ScrollTrigger.create({
            trigger: ".page4",
            onToggle: ({progress, direction, isActive}) => {
                if (isActive || direction > 0) {
                    document.getElementsByClassName("topbar")[0].classList.remove("dark")
                } else {
                    document.getElementsByClassName("topbar")[0].classList.add("dark")
                }
            },
            start: "top top"
        });


        gsap.registerPlugin(ScrollTrigger);
        const sections = gsap.utils.toArray(".slider");

        gsap.to(".page4 .background", {
            backgroundPositionY: "100%",
            scrollTrigger: {
                trigger: ".page4",
                scrub: true,
                toggleActions: "play none none reset",
                start: "top top",
                end: "+=" + DESKTOP_OFFSET_LONG
            }
        });

        let offset = DESKTOP_OFFSET_LONG;
        gsap.to(".page4 .background", {
            position: "fixed",
            scrollTrigger: {
                trigger: ".page4",
                toggleActions: "play none none reset",
                start: "top+="+ offset + " top"
            }
        });

        gsap.to(".page4 .content", {
            position: "fixed",
            scrollTrigger: {
                trigger: ".page4",
                toggleActions: "play none none reset",
                start: "top+="+ offset + " top"
            }
        });


        offset += DESKTOP_OFFSET_LONG;

        ScrollTrigger.create({
            trigger: ".page4",
            toggleActions: "play none none reset",
            onToggle: ({progress, direction, isActive}) => {
                if (isActive || direction > 0) {
                    document.getElementsByClassName("material")[0].classList.add("stroke");
                    document.getElementsByClassName("driven")[0].classList.remove("stroke")
                } else {
                    document.getElementsByClassName("material")[0].classList.remove("stroke");
                    document.getElementsByClassName("driven")[0].classList.add("stroke")
                }
            },
            start: "top+="+ offset + " top",
            end: "+=" + DESKTOP_OFFSET_LONG
        });

        gsap.to(sections,
            {
                x: -780,
                duration: 0.4,
                ease: "easeInOut",
                scrollTrigger: {
                    onToggle: ({progress, direction, isActive}) => {
                        if (isActive || direction > 0) {
                            setBold(1)
                        } else {
                            setBold(0)
                        }
                    },
                    trigger: ".page4",
                    toggleActions: "play none complete reverse",
                    start: "top+="+ offset + " top",
                    end: "+=" + DESKTOP_OFFSET_LONG
                }
            });


        offset += DESKTOP_OFFSET_LONG;

        gsap.fromTo(sections, 0.4,
            {},
            {
                x: -1500,
                ease: "easeInOut",
                scrollTrigger: {
                    trigger: ".page4",
                    onToggle: ({progress, direction, isActive}) => {
                        if (isActive || direction > 0) {
                            setBold(2)
                        } else {
                            setBold(1)
                        }
                    },
                    toggleActions: "play none complete reverse",
                    start: "top+="+ offset + " top",
                    end: "+=" + DESKTOP_OFFSET_LONG
                }
            });

    }, []);


    return (
        <div id="page4" className="page4">

            <div className="background"/>
            <div className="wrapper">
                <div className="content">
                    <div className="slide-wrapper">
                        <div className={"material slider " + (bold === 0 ? "":"stroke")}/>
                        <div className={"driven slider " + (bold === 1 ? "":"stroke")}/>
                        <div className={"design slider " + (bold === 2 ? "":"stroke")}/>
                    </div>
                    <div className="title">Material<span>ㅡ</span>Driven<span>ㅡ</span>Design</div>
                    <div className="desc">
                        소재―중심―디자인,<br/>
                        소재를 우선으로 연구하고 이를 바탕으로 실생활의 필요를 찾아 제품을 개발하는 방법론입니다. <br/>
                        이러한 방법론은 선행 연구 분야로서, 다양한 산업 영역에서 미래를 이끌어오고 있습니다.
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Page4;
