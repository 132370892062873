import Topbar from "./TopbarMobile";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7_Project from "./Page7-Project";
import Page7_Rnd from "./Page7-Rnd";
import Page8 from "./Page8";

export default () => {
    return (
        <div>
            <div id="pagepiling">
                <div className="m0-section" id="m0">
                    <Page1/>
                </div>
                <div className="m0-section">
                    <Page2/>
                </div>
                <div className="m0-section">
                    <Page3/>
                </div>
                <div className="m0-section">
                    <Page4/>
                </div>
                <div className="m0-section" id="about-us">
                    <Page5/>
                </div>
                <div className="m0-section" id="what-we-do">
                    <Page6/>
                </div>
                <div className="m0-section">
                    <Page7_Project/>
                </div>
                <div className="m0-section">
                    <Page7_Rnd/>
                </div>
                <div className="m0-section" id="contact">
                    <Page8/>
                </div>
            </div>

            <Topbar/>
        </div>
    )
}