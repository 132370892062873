/*global google*/
import './index.css';
import React, {useEffect, useRef, useCallback} from "react";
import {copyToClipboard} from "../../common/utils";
import ScrollTrigger from "gsap/ScrollTrigger"
import gsap from "gsap/gsap-core";
import GoogleMap from "../../common/GoogleMap";

function Page8() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
            trigger: ".page8-m",
            toggleActions: "play none play reset",
            pin: ".page8-m",
            pinSpacing: false,
            start: "top top",
            end: "+=200"
        });
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
            trigger: ".page8-m",
            onToggle: ({progress, direction, isActive}) => {
                if (isActive || direction > 0) {
                    document.getElementsByClassName("topbar-m")[0].classList.add("white")
                } else {
                    document.getElementsByClassName("topbar-m")[0].classList.remove("white")
                }
            },
            start: "top top"
        });
    }, []);


    return (
        <div className="page8-m" id="page8-m">
            <div className="background"/>
            <div className="content">
                <div className="about">Contact <br/>.</div>
                <div className="make-the-future-with-us">
                    MAKE THE
                    FUTURE<br/>
                    WITH US<br/>
                </div>
                <div className="map-wrapper">
                    <GoogleMap
                        styles={{
                            margin: "auto 24px",
                            height: 200,
                            backgroundColor: "var(--light-grey)"
                        }}/>
                    <div className="visit-us">

                        <div>
                            <div className="en"> New Business</div>
                            <div onClick={() => {
                                copyToClipboard("contact@mzero.kr")
                            }}>contact@mzero.kr
                            </div>
                            <div><a href="tel:[02-6952-3035]">02-6952-3035</a></div>
                        </div>
                        <div onClick={() => {
                            copyToClipboard("서울시 성동구 아차산로 15길 47-24, 1층")
                        }}>
                            <div className="en">
                                Visit Us
                            </div>
                            <div>
                                서울시 성동구 아차산로 15길
                            </div>
                            <div>
                                47-24, 1층
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="footer">
                <div className="content">

                    <div>
                        <div className={"info"}>
                            주식회사 랩엠제로
                        </div>
                        <div className="info-sub">
                            대표<span
                            style={{fontWeight: "normal"}}>신태호</span><span>|</span><span/>사업자번호<span>367-88-01517</span>
                        </div>
                        <div className={"info-dtl"}>
                            <div className={"row"}>
                                <div className={"text"}>메일<span>contact@mzero.kr</span></div>
                            </div>
                            <div className={"row"}>
                                <div className={"text"} onClick={() => {
                                    window.location.href = "tel:02-6952-3035"
                                }}>전화<span>02-6952-3035</span>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"text"}>팩스<span>02-6952-3037</span></div>
                            </div>
                            <div className={"row"}>
                                <div className={"text"}>주소<span style={{fontWeight: "normal"}}>서울시 성동구 아차산로 15길 47-24, 1층</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className={"sns"}>
                            <div className={"instagram"} onClick={() => {
                                window.location.href = "https://www.instagram.com/mzero_kr"
                            }}
                            />
                            <div className={"facebook"} onClick={() => {
                                window.location.href = "https://www.facebook.com/MZERO.KR"
                            }}/>
                        </div>

                        <div className={"copyright"}>
                            Copyright © 2020 M.0 All rights reserved.
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Page8;
