import './index.css';
import React, {useEffect} from "react";
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

function Page6() {

    const DESKTOP_OFFSET = 240;
    const DESKTOP_OFFSET_LONG = 600;


    useEffect(() => {
        ScrollTrigger.create({
            trigger: ".page6",
            onToggle: ({progress, direction, isActive}) => {
                if (isActive || direction > 0) {
                    document.getElementsByClassName("topbar")[0].classList.add("dark")
                } else {
                    document.getElementsByClassName("topbar")[0].classList.remove("dark")
                }
            },
            start: "top top"
        });

        gsap.registerPlugin(ScrollTrigger);

        let offset = 0;
        gsap.to(".page6 .wrapper", {
            position: "fixed",
            scrollTrigger: {
                trigger: ".page6",
                toggleActions: "play none play reset",

                start: "top top"
            }
        });

        offset += DESKTOP_OFFSET_LONG;

        // v 사라짐
        gsap.to(".page6 .content .letter-v",
            {
                opacity: 0,
                duration: .2,
                scrollTrigger: {
                    trigger: ".page6",
                    toggleActions: "play complete none reset",

                    start: "top+=" + offset + " top",
                    end: "+=" + DESKTOP_OFFSET_LONG,
                }
            });

        offset += DESKTOP_OFFSET_LONG;
        gsap.set(".page6 .content .v-desc", {
            y: +50
        });
        gsap.to(".page6 .content .v-desc", {
            opacity: 1,
            y: 0,
            duration: .2,
            scrollTrigger: {
                trigger: ".page6",
                toggleActions: "play complete play reset",

                start: "top+=" +offset+ " top",
                end: "+=" + DESKTOP_OFFSET_LONG,
            }
        });

        offset += DESKTOP_OFFSET_LONG;

        gsap.to(".page6 .content .v-desc", {
            opacity: 0,
            y: -50,
            duration: .2,
            scrollTrigger: {
                trigger: ".page6",
                toggleActions: "play complete none reset",

                start: "top+=" +offset+ " top",
                end: "+=" + DESKTOP_OFFSET_LONG,
            }
        });

        offset += DESKTOP_OFFSET_LONG;

        // 사라지고 p등장
        gsap.to(".page6 .content .letter-p",
            {
                opacity: 1,
                duration: .2,
                scrollTrigger: {
                    trigger: ".page6",
                    toggleActions: "play complete play reset",

                    start: "top+=" +offset+ " top",
                    end: "+=" + DESKTOP_OFFSET_LONG,
                }
            });

        offset += DESKTOP_OFFSET_LONG;

        // v 사라짐
        gsap.to(".page6 .content .letter-p",
            {
                opacity: 0,
                duration: .2,
                scrollTrigger: {
                    trigger: ".page6",
                    toggleActions: "play complete none reset",

                    start: "top+=" + offset + " top",
                    end: "+=" + DESKTOP_OFFSET,
                }
            });

        offset += DESKTOP_OFFSET;
        gsap.set(".page6 .content .p-desc", {
            y: +50
        });
        gsap.to(".page6 .content .p-desc", {
            opacity: 1,
            y: 0,
            duration: .2,
            scrollTrigger: {
                trigger: ".page6",
                toggleActions: "play complete play reset",

                start: "top+=" +offset+ " top",
                end: "+=" + DESKTOP_OFFSET_LONG,
            }
        });

        gsap.fromTo(".page6 .wrapper", {
            top: 0,
        }, {
            position: "absolute",
            top: "unset",
            bottom: 0,
            scrollTrigger: {
                trigger: ".page7",
                toggleActions: "play none play reset",

                start: "top bottom"
            }
        });

    }, []);


    return (
        <div className="page6 panel" id="what-we-do">
            <div className="wrapper">
                <div className="content">
                    <div className="about">What We Do <br/>.</div>
                    <div className="box-wrapper">
                        <div className="align-box">
                            <div className="letter-o"/>
                        </div>
                        <div className="align-box">
                            <div className="letter-p"/>
                            <div className="letter-v"/>
                            <div className="p-desc">
                                <div className="title">
                                    Zero Plastic
                                </div>
                                <div className="cnt">
                                    소재의 지속가능성
                                </div>
                                <div className="eng">
                                    Materials for Sustainability
                                </div>
                            </div>
                            <div className="v-desc">
                                <div className="title">
                                    Zero Virus
                                </div>
                                <div className="cnt">
                                    생활 환경 개선
                                </div>
                                <div className="eng">
                                    Improvement in Living Environment
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page6;
